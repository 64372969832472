import React from 'react'
import Layout from "../components/layout";
import '../styles/about.scss';

const About = () => {

    return (
        <Layout>
        <main>
            <div className="About">
                <div className="about-headers">
                    <h1>ABOUT DD ZONE</h1>
                </div>
                <div className="aboutus-para">
                   <h1>Our Goal</h1>
                    <p>
                        To give the experience of the agility, scalability, availability, and performance benefits that valet parking has to offer.
                    </p>
                    <h1>Who We Are..</h1>
                    <p>
                        DD Zone, has been profitable every year since 2018. Our team is well versed in strategy, technology, lean
                        development, Six Sigma, ITIL, Project & Program Management, Portfolio Management, & IT Security - PCI-DSS, and HIPPA.<br/>
                        <br/>
                        We are a heavy Engineering focused company with capabilities that include pre-sales and post-sales deployment. DD Zone
                        associates are seasoned executives from Fortune 500 companies like UPS, Humana, GE, Southern Companies, and Western
                        Southern Financial Group.<br/><br/>
                        Our mission is to create solutions for profitability and success.We offer integrated and synergistic technology
                        and marketing solutions for global businesses. We are dedicated to developing fully customized, accountable
                        programs to address each client's unique situation. We are further committed to analyzing the analytics data we
                        collect and translating it into kernels of actionable business intelligence that can directly impact each client's
                        growth and success.<br/><br/>We support our customers through professional applications, first-class service and
                        reliable local service. Being close to our customers in all respects is our top priority.
                    </p>
                </div>
            </div>

            <div className="clients">
                <div className="client-row">
                    <div className="renow">
                        <h2>
                            Our Renowned Clients
                        </h2>
                        <p>We have some renowned clients who are happy with our services. <br/>They are excellent in their work and 
                            we make sure to give them best of our services to give them best of their experiences.</p>
                            <h2>What they says about DD Zone</h2>
                            <p>It has been a real pleasure dealing with the team. They are professional, responsive and helpful.<br/>
                                We look forward to engaging our future technical services powered by DD Zone.</p>
                    </div>

                    <section className='clients'>
                        <div className='clients-title'>
                            <h1>Our Clients</h1>
                        </div>
                        <div className='clients-container'>
                            <img className='logo' src='../images/crowne-plaza.png' alt='image'/>
                            <img className='logo' src='../images/radisson.png' alt='image'/>
                            <img className='logo' src='../images/cambria.png' alt='image'/>
                            <img className='logo' src='../images/doubletree.png' alt='image'/>
                            <img className='logo' src='../images/fantasy-world.png' alt='image'/>
                            <img className='logo' src='../images/embassy.png' alt='image'/>
                            <img className='logo' src='../images/holiday_Inn.png' alt='image'/>
                            <img className='logo' src='../images/archer-hotel.png' alt='image'/>
                            <img className='logo' src='../images/ritz-carlton.png' alt='image'/>
                            <img className='logo' src='../images/wyndham.png' alt='image'/>
                        </div>
                    </section>

                </div>
            </div>
        </main>
        </Layout>
    )}

export default About

